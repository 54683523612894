import React, {useState} from "react";
import { 
    List,
    Datagrid,
    TextField,
    TopToolbar, 
    CreateButton, 
    ReferenceField, 
    TextInput, 
    Button, 
    Filter 
} from 'react-admin';
import geosArray from "../../Dictionaries/geos";
import SideBar from "../Common/SideBar";
import CompanySideFilter from "./Filters/CompanySideFilter";
import ContentFilter from '@material-ui/icons/FilterList';
import CsvExporter from "../Common/CsvExporter";
import CustomPagination from "../Common/Pagination";

export const CompanyList = (props) => {
    const [showFilter, setShowFilter] = useState(false);


    const AListActions = (props) => (
        <TopToolbar>
            <CreateButton/>
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
            <CsvExporter />
        </TopToolbar>
    );

    return (
    <List {...props} 
        bulkActionButtons={false} 
        title="Companies" 
        filters={
            <Filter>
                <TextInput label="Search" source="q" alwaysOn /> 
                <SideBar flag={showFilter} flagSetter={setShowFilter} alwaysOn>
                    <CompanySideFilter />
                </SideBar>
            </Filter>
        } 
        actions={<AListActions {...props} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="id_number" label="Identifier"/>
            <TextField source="geo" />
            <ReferenceField label="Proxy" source="proxy_server_id" reference="proxy-server">
                <TextField source="name" /> 
            </ReferenceField>
        </Datagrid>
    </List>
)};