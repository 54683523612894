
import React from "react";

import {
    ReferenceInput,
    SelectInput,
    DateInput,
    NumberInput,
    usePermissions,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Box, CardContent, Card as MuiCard } from '@material-ui/core';
import transactionStatuses from "../../../Dictionaries/transStatuses";

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            // width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex'
            // marginTop: '1.5em'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const UserSideFilter = (props) => {
    const {permissions} = usePermissions();
    return (
            <MuiCard {...props} style={
                {
                    margin: '0', 
                    padding: '0', 
                    width: '100%', 
                    height: '100%',  
                    display: 'flex', 
                    top: 0, 
                    alignItems: 'flex-start',
                    marginTop: '2em'
                }
            }>
                <CardContent 
                    style={
                    {
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        heith: '100%',
                        transition: 'width 2s, height 2s, background-color 2s, transform 2s',

                    } }
                    alwaysOn
                    >
                        <ReferenceInput label="Account" source="account_id" reference="account" perPage={1000}  sort={{field: 'code', order: 'ASC'}}>
                            <SelectInput optionText="code"  resettable/>
                        </ReferenceInput>
                        {(permissions === 'owner' || permissions === 'teamlead') &&
                        <ReferenceInput label="User" source="user_id" reference="user" perPage={1000} sort={{field: 'login', order: 'ASC'}}>
                            <SelectInput optionText="login" resettable />
                        </ReferenceInput>}
                        <SelectInput label="Status" source="status" choices={transactionStatuses} resettable/>
                        <Box>
                            <Box>
                                Amount
                            </Box>
                            <Box display={'inline-flex'}>
                                <NumberInput source="amount_from" label="from" format={v => v /100} parse={v => v * 100}/>
                                <Box>&nbsp;</Box>
                                <NumberInput source="amount_to" label="to" format={v => v /100} parse={v => v * 100}/>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                Created
                            </Box>
                            <Box display={'inline-flex'} >
                                <DateInput source="created_at_from" label="from"  />
                                <Box>&nbsp;</Box>
                                <DateInput source="created_at_to" label="to"   />
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                Completed
                            </Box>
                            <Box display={'inline-flex'} >
                                <DateInput source="completed_at_from" label="from"  />
                                <Box>&nbsp;</Box>
                                <DateInput source="completed_at_to" label="to"   />
                            </Box>
                        </Box>
                    </CardContent>
                </MuiCard>)
};

export default UserSideFilter;