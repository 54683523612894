
import React from "react";
import {
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import { CardContent, Card as MuiCard } from '@material-ui/core';
import geosArray from "../../../Dictionaries/geos";

const CompanySideFilter = (props) => {
    return (      
            <MuiCard {...props} style={
                {
                    margin: '0', 
                    padding: '0', 
                    width: '100%', 
                    height: '100%',  
                    display: 'flex', 
                    top: 0, 
                    alignItems: 'flex-start',
                    marginTop: '2em'
                }
            }>
                <CardContent 
                    style={
                    {
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        heith: '100%',
                        transition: 'width 2s, height 2s, background-color 2s, transform 2s',

                    } }
                >
                    <SelectInput label="Geo" source="geo" choices={geosArray} resettable />
                    <ReferenceInput label="Proxy" source="proxy_server_id" reference="proxy-server" perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                        <SelectInput optionText="name"  resettable/>
                    </ReferenceInput>
                </CardContent>
            </MuiCard>
    )
};

export default CompanySideFilter;