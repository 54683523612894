
const maxCardNumberLength = 20
export default function formatCardNumber(number) {
    let s = number ? number.replace(/[^0-9]+/g, "") : ''
    if (!s || s.length < 5)
        return number

    let formattedNumber = s.substring(0, maxCardNumberLength).split('').map(( val, index) => {
        if (((index+1) % 4) === 0 ) 
            return `${val} `
        else
            return `${val}`
    })

    return formattedNumber.join('').trim()
}