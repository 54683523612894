import React, { useMemo } from 'react';
import { TextInput, required } from 'react-admin';
import { useFormState } from 'react-final-form';

export const ExternalConnectionInput = props => {
    const {values} = useFormState();
    const field = useMemo((bank) => {
        switch(values.bank) {
            case 'airwallex':
            case 'qonto':
                return <TextInput label="ExternalConnection" source="external_id"  validate={required()} fullWidth/>
            default:
                return <></>
        }
    },[values.bank])

    return (
        values.bank ? field : null
    )
}