
import React from 'react';
import { 
    ShowButton, 
    SimpleForm, 
    TextInput, 
    SelectInput,
    ReferenceInput,
    TopToolbar, 
    required, 
    BooleanInput, 
    Edit, 
    CreateButton
} from 'react-admin';
import { Box } from '@material-ui/core';
import monthes from '../../../Dictionaries/monthes';
import years from '../../../Dictionaries/yaers';
import formatCardNumber from '../../../Utils/formatCardNumber';
import cardStatuses from '../../../Dictionaries/cardStatuses';

const CardDateExpInput = (props) => {
    return (
        <Box display={'inline-flex'} style={{'width': 'auto'}}>
            <SelectInput  label="Month" source="exp_month" choices={monthes} optionText="month" validate={required()} style={{'maxWidth': '46%', 'minWidth': '98%'}} />
            &nbsp;
            <SelectInput  label="Year" source="exp_year" choices={years} optionText="year" validate={required()} style={{'maxWidth': '46%', 'minWidth': '98%'}} />
        </Box>
    )
}
const CardEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <CreateButton basePath={basePath}/>
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const AdminCardEdit = (props) => (
    <Edit {...props} actions={<CardEditActions />}>
        <CardEditComponent />
    </Edit>
)



const CardEditComponent = (props) => { 
    const {record} = props;
    return (
        <SimpleForm {...props}>
            <TextInput disabled label="Id" source="id" />
            {record.manual_input && 
                <Box>
                    <ReferenceInput label="Account" source="account_id" reference="account" perPage={1000} fullWidth sort={{field: 'code', order: 'ASC'}}>
                        <SelectInput optionText="description" validate={required()} />
                    </ReferenceInput>
                    <TextInput label="CardHolder" source="cardholder"  fullWidth/>
                
                    <TextInput 
                        label="Card Number"
                        source="pan" 
                        fullWidth
                        validate={required()}
                        format={v => formatCardNumber(v)}
                        parse={v => v.replace(/[^0-9]+/g, "")}
                        onKeyPress={(event)=> {
                            if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
                                event.preventDefault(); 
                            }
                        }}
                        onPaste={(event) => {
                            let paste = (event.clipboardData || window.clipboardData).getData('text');
                            paste = formatCardNumber(paste)
                            event.target.replace = paste
                        }}
                    />

                    <CardDateExpInput />
                    <TextInput 
                        label="Code" 
                        source="code" 
                        fullWidth
                        validate={required()}
                        onKeyPress={(event)=> {
                            if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
                                event.preventDefault(); 
                            }
                            if(event.target.value.length >= 3) {
                                event.preventDefault(); 
                            }
                        }}
                    />
                </Box>}
                <Box>
                    <TextInput label="Label" source="label" fullWidth/>
                    <SelectInput label="status" source="status"  choices={cardStatuses} fullWidth />
                    <BooleanInput label="hidden" source="hidden"  defaultValue fullWidth />
                </Box>
        </SimpleForm>
)}

