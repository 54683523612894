import {
    useListContext,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    Button,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required,
    useRedirect,
} from 'react-admin';
import Shuffle from '@material-ui/icons/Shuffle'
import { Box, CardContent, Card as MuiCard } from '@material-ui/core';
import config from '../../../config';
import { useState } from 'react';


const ChangeCardOwner = () => {
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('card');
    const redirect = useRedirect();
    const [loading, setLoading] = useState(false);


    const handleCardOwnerChange = async (event) => {
        const {user_id : {value: uid}} = event.target;
        redirect(false);

        setLoading(true);

        await fetch(`${config.apiUrl}/card/reassign`, { 
                method: 'POST', 
                body: JSON.stringify({ user_id: uid, card_ids: selectedIds}), 
                credentials: 'include', 
                Accept: 'application/json', 
                headers: {
                    'Content-Type': 'application/json'
                } 
            })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                } else {
                    onSuccess()
                }
            })
            .catch(e => {
                onFailure(e.message)
            })
            .finally(() => {
                unselectAll();
                setLoading(false);
            })
    }

    const onSuccess = () => {
        notify(`Card owner changed`, {
            type: 'info'
        });
        refresh()
    };

    const onFailure = (message) => {
        notify(`Owner can't be changed ${message}`,{
            type: 'error'
        });
        refresh(); 
    };

    return (
        <SimpleForm 
            toolbar={false}
            style={
                {
                    'padding': 0
                }
            }
            onSubmit={handleCardOwnerChange}
        >
            <Box style={
                {
                    'display': 'inline-flex',
                    'flexDirection': 'row', 
                }}>
                <ReferenceInput label="User" reference="user" source="user_id"  sort={{field: 'login', order: 'ASC'}}>
                    <SelectInput optionText="login" validate={required()} fullWidth resettable/>
                </ReferenceInput>
                <Button
                    label="change"
                    disabled={loading}
                    type="submit"
                    fullWidth
                    style={
                        {
                            'display' :'inline-flex'
                        }
                    }
                >
                    <Shuffle />
                </Button>
            </Box>
        </SimpleForm>
    );
};

export default ChangeCardOwner;