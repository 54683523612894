
import React from 'react';
import { 
    Create,
    SimpleForm,
    SaveButton,
    SelectInput,
    ReferenceInput,
    required,
    Toolbar,
    useNotify,
    useRefresh,
    useRedirect,
    TextInput,
    usePermissions,
    BooleanInput,
    FormDataConsumer
} from 'react-admin';
import { Box } from '@material-ui/core';
import config from '../../config';

const CardIssueToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Add card" />
    </Toolbar>
);


export const CardIssue = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const {permissions} = usePermissions();

    const path = "card/issue";


    const handleCardIssueSubmit = (props) => {
        const {account_id} = props;
        return fetch(`${config.apiUrl}/${path}`, { 
                method: 'POST', 
                body: JSON.stringify({...props}), 
                credentials: 'include', 
                Accept: 'application/json', 
                headers: {
                    'Content-Type': 'application/json'
                } 
            })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(data => {
                        onFailure(data.message) 
                    })
                } else {
                    response.json()
                    .then(json => onSuccess(json.data))
                }
                
            })
            .catch(e => {
                console.log("error", e)  
            })
    }

    const onSuccess = (data) => {
        notify(`Card added`, 'success');
        redirect(`/card/${data.id}/show`);
        refresh();
    };

    const onFailure = (message) => {
        notify(`${message}`,'error');
        refresh(); 
    };

    return (
    <Create title="Add card" {...props} >
        <SimpleForm  save={handleCardIssueSubmit} toolbar={<CardIssueToolbar />}> 
            <Box>
                <ReferenceInput label="Account" source="account_id" reference="account" perPage={1000} sort={{field: 'code', order: 'ASC'}}>
                    <SelectInput 
                        optionText={ record => 
                            `${record.description} ${record.code} ${record.cards_limit - record.cards_left}/${record.cards_limit}`}
                        validate={required()} 
                        fullWidth
                    />
                </ReferenceInput>
                <TextInput label="Label" source="label" fullWidth/>
                {(permissions == 'owner' || permissions == 'teamlead') &&
                    <Box>
                        <BooleanInput label="for Myself" source="self" fullWidth defaultValue={true}/>
                        <FormDataConsumer>
                        {({ formData }) => 
                            (!formData.self &&
                                <ReferenceInput label="User" source="user_id" reference="user" perPage={1000} sort={{field: 'login', order: 'ASC'}} fullWidth>
                                    <SelectInput optionText="login" />
                                </ReferenceInput>
                            )}
                        </FormDataConsumer>
                    </Box>
                }
            </Box>
        </SimpleForm>
    </Create>
)};