
import React from 'react';
import { 
    Create,
    SimpleForm, 
    TextInput, 
    BooleanInput, 
    PasswordInput, 
    SelectInput, 
    ReferenceInput, 
    required, 
    usePermissions,
    FormDataConsumer
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { AdminUserCreate } from './CreateForms/AdminUserCreate';
import { OwnerUserCreate } from './CreateForms/OwnerUserCreate';



const TeamSelect = props => {
    const record = useRecordContext(props)
    console.log(record)
    if (!record?.role)
        return null
    const {choices} = props
    const uniqueChoices = [...new Set(choices)]
    return <SelectInput choices={uniqueChoices} resettable/>
}

export const UserCreate = (props) => {
    const {permissions} = usePermissions();

    return (
            permissions == 'admin' ? 
                <AdminUserCreate {...props} /> : 
                    permissions == 'owner' ?
                        <OwnerUserCreate  {...props} /> :
                            <Create {...props}>
                                <SimpleForm>
                                    <TextInput label="login" source="login" validate={required()} />
                                    <PasswordInput label="password" source="password" validate={required()}/>
                                    <BooleanInput label="Active" source="is_active" defaultValue />
                                </SimpleForm>
                            </Create>
                            )
};