import React from 'react';
import { BooleanInput, Edit,  NumberInput,  SimpleForm} from 'react-admin';

export const AccountEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput label="Description" source="description" /> */}
            <NumberInput label="Stash Card Limit" source="cards_required" min={0}/>
            <NumberInput label="Monthly Issue Card Limit" source="cards_limit" min={0}/>
            <BooleanInput source="archived" />
        </SimpleForm>
    </Edit>
);