import React, {useState} from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    BooleanField,
    FunctionField, 
    TopToolbar, 
    CreateButton,
} from 'react-admin';
import CustomPagination from "../Common/Pagination";


export const ProxyList = (props) => {

    const AListActions = (props) => (
        <TopToolbar>
            <CreateButton/>
        </TopToolbar>
    )

    return (
    <List 
        {...props} 
        bulkActionButtons={false} 
        title="Proxy" 
        actions={<AListActions/>}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <TextField source="name" />
            <FunctionField label="host" render={record => `${record.url}:${record.port}`} sortable={false}/>
            <BooleanField source="is_valid" label="Valid"/>
        </Datagrid>
    </List> )
};