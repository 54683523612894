const years = [
  {id:2022, year: '22'},
  {id:2023, year: '23'},
  {id:2024, year: '24'},
  {id:2025, year: '25'},
  {id:2026, year: '26'},
  {id:2027, year: '27'},
  {id:2028, year: '28'},
  {id:2029, year: '29'},
  {id:2030, year: '30'},
  {id:2031, year: '31'},
  {id:2032, year: '32'},
  {id:2033, year: '33'},
  {id:2034, year: '34'},
  {id:2035, year: '35'},
]

export default years;