
// in src/posts.js
import React from 'react';
import { Edit,  SimpleForm, TextInput, PasswordInput, NumberInput, required} from 'react-admin';

export const ProxyServerEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="name" source="name" validate={required()} />
            <TextInput label="host" source="url" validate={required()} />
            <NumberInput label="port" source="port" validate={required()} />
            <TextInput label="login" source="user" validate={required()} />
            <PasswordInput label="password" source="password" validate={required()}/>
        </SimpleForm>
    </Edit>
);