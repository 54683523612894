async function fallbackCopyToClipboard (text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.append(textArea);
    textArea.focus();
    textArea.select();
  
    let error = null;
  
    try {
      const successful = document.execCommand('copy');
      const message = successful ? 'successful' : 'unsuccessful';
  
      if (message === 'unsuccessful') {
        error = 'Unable to copy';
      }
    } catch (error_) {
      error = error_;
    }
    document.body.removeChild(textArea);
  
    if (error) {
      throw new Error(error);
    }
  }
  
  export async function copyToClipboard (text) {
    if (!navigator.clipboard) {
      return fallbackCopyToClipboard(text);
    }
  
    return navigator.clipboard.writeText(text);
  }
  