
import React from 'react';
import { 
    ShowButton, 
    SimpleForm, 
    TextInput, 
    SelectInput,
    ReferenceInput,
    TopToolbar, 
    required, 
    BooleanInput, 
    Edit, 
    CreateButton
} from 'react-admin';
import cardStatuses from '../../../Dictionaries/cardStatuses';

const CardEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <CreateButton basePath={basePath}/>
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);

export const UserCardEdit = (props) => (
    <Edit {...props} actions={<CardEditActions />}>
        <SimpleForm >
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Label" source="label" />
            <ReferenceInput label="card owner" source="user_id" reference="user" perPage={1000} sort={{field: 'login', order: 'ASC'}}>
                <SelectInput optionText="login" />
            </ReferenceInput>
            <SelectInput label="status" source="status"  choices={cardStatuses} />
        </SimpleForm>
    </Edit>
)

