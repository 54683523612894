
// in src/posts.js
import React from 'react';
import { Create,  SimpleForm, TextInput, ReferenceInput, PasswordInput, SelectInput, NumberInput, required} from 'react-admin';

export const ProxyServerCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="name" source="name" validate={required()} />
            <TextInput label="host" source="url" validate={required()} />
            <NumberInput label="port" source="port" validate={required()} />
            <TextInput label="login" source="user" validate={required()} />
            <PasswordInput label="password" source="password" validate={required()}/>
        </SimpleForm>
    </Create>
);