
import React from 'react';
import { 
    Edit,
    SimpleForm, 
    TextInput, 
    BooleanInput, 
    PasswordInput, 
    SelectInput, 
    ReferenceInput, 
    required,
    FormDataConsumer,
    SaveButton,
    Toolbar
} from 'react-admin';
import { Box } from '@material-ui/core';


const AdminUserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export const AdminUserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<AdminUserEditToolbar />}>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="login" source="login" validate={required()} />
            <PasswordInput label="password" source="password" validate={required()}/>
            <ReferenceInput source="role" reference="role" perPage={1000}>
                <SelectInput validate={required()}  translateChoice={false}/>
            </ReferenceInput>
            <Box>
                <FormDataConsumer>
                    {({ formData }) => 
                        <Box>
                            {formData.role && formData.role !== 'admin' &&
                            <ReferenceInput label="Team" source="team_id" reference="team" perPage={1000} sort={{field: 'pp_id', order: 'ASC'}} fullWidth >
                                <SelectInput optionText="pp_id"  validate={required()} resettable />
                            </ReferenceInput>}
                            {formData.team_id && formData.role !== 'admin' && formData.role !== 'owner' &&
                            <ReferenceInput source="parent_id" reference="user" perPage={1000} sort={{field: 'login', order: 'ASC'}} fullWidth >
                                <SelectInput optionText="login" validate={required()} resettable/>
                            </ReferenceInput>}
                        </Box>
                    }
                </FormDataConsumer>
            </Box>
            <BooleanInput label="Active" source="is_active" defaultValue />
        </SimpleForm>
    </Edit>
);