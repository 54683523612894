import React from 'react';
import { Edit,  SimpleForm, TextInput, SelectInput, ReferenceInput, required} from 'react-admin';

export const PaymentSystemAccountEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <ReferenceInput label="Team" source="team_id" reference="team" perPage={1000} sort={{field: 'pp_id', order: 'ASC'}}>
                <SelectInput optionText="pp_id"  validate={required()} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);