import * as React from "react";
import { List, Datagrid, TextField, SelectInput, FilterButton, TopToolbar, CreateButton, ExportButton, TextInput, ReferenceField,  } from 'react-admin';
import geosArray from "../../Dictionaries/geos";
import CsvExporter from "../Common/CsvExporter";
import CustomPagination from "../Common/Pagination";

const companyCredFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const AListActions = (props) => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
        <CsvExporter />
    </TopToolbar>
);


export const CompanyCredentialsList = (props) => (
    <List 
        {...props}
        bulkActionButtons={false} 
        title="Companies" 
        filters={companyCredFilters} 
        actions={<AListActions {...props} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <ReferenceField label="Company" source="company_id" reference="company">
                <TextField source="name" /> 
            </ReferenceField>
            <TextField source="bank" />
        </Datagrid>
    </List>
);