import React, { useState } from 'react';
import { 
    Show, 
    SimpleShowLayout, 
    TextField, 
    FunctionField, 
    EditButton, 
    TopToolbar, 
    CreateButton, 
    usePermissions,
    Button,
    useRecordContext,
    useNotify,
    useRefresh,
} from 'react-admin';
import formatCardNumber from "../../Utils/formatCardNumber";
import AmountField from "../Common/AmountField";
import FileCopy from '@material-ui/icons/FileCopy';
import { copyToClipboard } from '../../Utils/copyToClipboard';
import pad from '../../Utils/pad';
import BlockIcon from '@material-ui/icons/Block';
import config from "../../config";

const CardShowAdminActions = ({ basePath, data, resource }) => {  
    return (
    <TopToolbar>
        <CreateButton basePath={basePath}/>
        <EditButton basePath={basePath} record={data} />
        <BlockCardComponent />
    </TopToolbar>
)};

const CardShowOwnerActions = ({ basePath, data, resource }) => {  
    return (
    <TopToolbar>
        <CreateButton basePath={basePath}/>
        <BlockCardComponent />
    </TopToolbar>
)};

const BlockCardComponent = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const {id, status} = record
    const handleCardBlock = (props) => {
        return fetch(`${config.apiUrl}/card/${id}/block`, {
            method: 'POST',
            credentials: 'include',
            Accept: 'application/json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                response.json().then(data => {
                    onFailure(data.message)
                })
            } else {
                response.json()
                    .then(json => onSuccess(json.data))
            }

        })
        .catch(e => {
            console.log("error", e)
        })
    }

    const onSuccess = (data) => {
        notify(`Card blocked`, 'success');
        refresh();
    };

    const onFailure = (message) => {
        notify(`${message}`,'error');
        refresh();
    };

    return status === 'active'? <Button label="Block" onClick={handleCardBlock}><BlockIcon/></Button> : null
}

const CopyCredentialsComponent = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const copyCardCredentials = () => {
        try {
            copyToClipboard(`${record.pan} ${pad(record.exp_month, 2)}/${record.exp_year.toString().slice(-2)} ${record.code}`)
            notify(`Card credentials copied`, 'info');
            refresh();
        } catch {
            notify(`Can't copy`, 'error');
            refresh();
        }
    }

    return <Button label="Copy" onClick={copyCardCredentials}><FileCopy/></Button>
}

export const CardShow = (props) => {
    const {permissions} = usePermissions();
    const [visibleCode, setVisibleCode] = useState(false)

    return (
    <Show 
        {...props}
        actions={ 
            permissions === 'admin' ? <CardShowAdminActions /> : 
            permissions === 'owner' ? <CardShowOwnerActions /> : null
        }>
        <SimpleShowLayout>
            <CopyCredentialsComponent />
            <FunctionField label="Card Number" render={v => formatCardNumber(v.pan)} key={'cn'} />
            <FunctionField label="ExpDate" render={record => `${pad(record.exp_month, 2)}/${record.exp_year.toString().slice(-2)}`} key={'ed'} />
            <AmountField source="amount"  options={{ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }} scale={2} />
            <TextField source="currency" />
            <TextField source="cardholder" />
            <FunctionField label="code" render={v => visibleCode ? v.code : '***'} onClick={() => setVisibleCode(!visibleCode)} style={{cursor: 'pointer'}}  key={'cv'}/>
        </SimpleShowLayout>
    </Show>)
};