import {React} from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    TopToolbar,
    TextInput,
    Filter,
    usePermissions,
} from 'react-admin';
import AmountField from "../../Common/AmountField";
import CsvExporter from "../../Common/CsvExporter";


export const UserAccountList = (props) => {
    const {permissions} = usePermissions()
    const AListActions = (props) => (
        <TopToolbar>
            <CsvExporter />
        </TopToolbar>
    );

    return (
    <List 
        {...props} 
        bulkActionButtons={false} 
        title="Accounts"
        filters={
            <Filter>
                <TextInput label="Search" source="q" alwaysOn />
            </Filter>} 
        actions={<AListActions />}
    >
        <Datagrid rowClick={null}>
            <TextField source="id" />
            <TextField source="code" />
            <TextField source="cards_limit" label="Monthly Card Limit" />
            <TextField source="cards_left" label="Card Issue Left" />
            <TextField source="description" label="Description" />
            {permissions == 'owner' && <AmountField 
                label="amount" 
                source="amount"  
                options={{ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }} 
                scale={2}
            />}
            {permissions == 'owner' && <TextField source="currency" />}
        </Datagrid>
    </List>)
};