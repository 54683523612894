import React from 'react';
import { 
    Button,
    useListContext,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { stringify } from 'query-string';
import config from '../../config';




const CsvExporter = () => {
    const {basePath, currentSort, filterValues, filter} = useListContext();
    const download = () => {
        window.location = `${config.backUrl}${basePath}/download?${stringify({...filterValues, ...filter, sort: currentSort?.field, order: currentSort?.order})}`
    }

    return (
        <Button label="Export" onClick={download}>
            <DownloadIcon/>
        </Button>

)};


export default CsvExporter;