import React from 'react';
import { Create,  SimpleForm, TextInput, SelectInput, ReferenceInput, required} from 'react-admin';
import { ExternalConnectionInput } from './ExternalConnectionInput';
import { Box } from '@material-ui/core';

export const PaymentSystemAccountCreate = (props) => {
    return (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Team" source="team_id" reference="team" perPage={1000} sort={{field: 'pp_id', order: 'ASC'}}>
                <SelectInput optionText="pp_id"  validate={required()} />
            </ReferenceInput>
            <ReferenceInput label="Company" source="company_id" reference="company" perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText="name"  validate={required()} />
            </ReferenceInput>
            <ReferenceInput label='PaymentSystem' source='bank' reference='bank' perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText='name'   validate={required()} />
            </ReferenceInput>
            <Box>
                <ExternalConnectionInput />
            </Box>
        </SimpleForm>
    </Create>)
};