import React, {useState} from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    TopToolbar, 
    ExportButton, 
    TextInput, 
    Filter, 
    ReferenceField, 
    Button,
    useListContext,
} from 'react-admin';
import {red, yellow} from "@material-ui/core/colors"
import DashboardSideFilter from "./Filters/DashboardSideFilter";
import ContentFilter from '@material-ui/icons/FilterList';
import Sidebar from "../Common/SideBar";
import CsvExporter from "../Common/CsvExporter";

const dRowStyle = record => {
    const diff = record.diff;
    const color = diff <=0 ? red[200] : diff < record.limit / 10 ? yellow[600] : 'initial'; 
    return {
        backgroundColor: color
    }
}

export const DashboardList = (props) => {
    const [showFilter, setShowFilter] = useState(false);

    const AListActions = (props) => {
    
        return (
        <TopToolbar>
            <Button label="Filter" onClick={()=> setShowFilter(true)}>
                <ContentFilter/>
            </Button>
            <CsvExporter />
        </TopToolbar>
    )};

return (
    <List 
        {...props} 
        bulkActionButtons={false} 
        title="Stats" 
        filters={
            <Filter>
                <TextInput label="Search" source="q" key={'q'} alwaysOn /> 
                <Sidebar flag={showFilter} flagSetter={setShowFilter} key={'sidebar'} alwaysOn>
                    <DashboardSideFilter />
                </Sidebar>
            </Filter>
       } 
        actions={<AListActions />}
        sort={{ field: 'diff', order: 'ASC' }}
        pagination={false}
    >
        <Datagrid rowStyle={dRowStyle}>
            <ReferenceField source="team_id" reference="team">
                <TextField source="pp_id" />
            </ReferenceField>
            <ReferenceField source="account_id" reference="account">
                <TextField source="code" />
            </ReferenceField>
            <ReferenceField source="account_id" reference="account" label="Description">
                <TextField source="description" />
            </ReferenceField>
            <TextField source="cards_count" label="Cards"/>
            <TextField source="active_cards_count" label="Active cards" align="center" />
            <TextField source="blocked_cards_count" label="Blocked cards"/>
            <TextField source="hidden_cards_count" label="Hidden cards" />
            <TextField source="limit" label="Hidden's limit" />
            <TextField source="diff" label="Diff" />
        </Datagrid>
    </List>
)};