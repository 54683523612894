
import React, {useState} from "react";

import { 
    ReferenceInput,
    SelectInput,
    NumberInput,
    useChoicesContext,
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import { Box, CardContent, Card as MuiCard } from '@material-ui/core';
import transactionStatuses from "../../../Dictionaries/transStatuses";

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            // width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex'
            // marginTop: '1.5em'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);


const PPIDselect = props => {
    const {choices} = props
    const uniqueChoices = [...new Set(choices.map(x =>  x.pp_id))].map(x => ({pp_id:`${x}`}))
    return <SelectInput label="PPid" source="pp_id"  optionText="pp_id" optionValue="pp_id"  choices={uniqueChoices} resettable/>
}

const AdminSideFilter = (props) => {

    return (
            <MuiCard {...props} style={
                {
                    margin: '0', 
                    padding: '0', 
                    width: '100%', 
                    height: '100%',  
                    display: 'flex', 
                    top: 0, 
                    alignItems: 'flex-start',
                    marginTop: '2em'
                }
            }>
                <CardContent 
                    style={
                    {
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        heith: '100%',
                        transition: 'width 2s, height 2s, background-color 2s, transform 2s',

                    } }
                    alwaysOn
                >
                        <ReferenceInput label="Company" source="company_id" reference="company" sort={{field :'name', order: 'ASC'}} perPage={1000}>
                            <SelectInput optionText="name" resettable/>
                        </ReferenceInput>
                        <ReferenceInput label="Team" source="team_id" reference="team" perPage={1000} sort={{field: 'pp_id', order: 'ASC'}}>
                            <SelectInput optionText="pp_id" resettable/>
                        </ReferenceInput>
                        <Box>
                            <Box>
                                Amount
                            </Box>
                            <Box display={'inline-flex'}>
                                <NumberInput source="amount_from" label="from" format={v => v /100} parse={v => v * 100}/>
                                <Box>&nbsp;</Box>
                                <NumberInput source="amount_to" label="to" format={v => v /100} parse={v => v * 100}/>
                            </Box>
                        </Box>
                    </CardContent>
            </MuiCard>
    )
};

export default AdminSideFilter;