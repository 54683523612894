
// in src/posts.js
import React from 'react';
import { 
    Edit,  
    SimpleForm, 
    TextInput, 
    BooleanInput, 
    PasswordInput, 
    SelectInput, 
    ReferenceInput, 
    usePermissions,
    required,
    useEditContext,
    FormDataConsumer
} from 'react-admin';
import { useRecordContext } from 'ra-core';
import { AdminUserEdit } from './EditForms/AdminUserEdit';
import { OwnerUserEdit } from './EditForms/OwnerUserEdit';


const TeamSelect = props => {
    const record = useRecordContext(props)
    const o = useEditContext()
    console.log(o)
    if (!record?.role)
        return null
    const {choices} = props
    const uniqueChoices = choices
    return <SelectInput source="team_id" optionText="pp_id" optionValue="id" choices={uniqueChoices} resettable translateChoice={false}/>
}


export const UserEdit = (props) => {
    const {permissions} = usePermissions();
    return (
        permissions === 'admin' ?
            <AdminUserEdit {...props} /> :
                permissions === 'owner' ?
                    <OwnerUserEdit {...props} /> :
                        <Edit {...props}>
                            <SimpleForm>
                                <TextInput disabled label="Id" source="id" />
                                <TextInput label="login" source="login" />
                                <PasswordInput label="password" source="password" />
                                <BooleanInput label="Active" source="is_active" defaultValue />
                            </SimpleForm>
                        </Edit>)
};