import React, { useEffect, useState } from 'react';
import { TextInput, required } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';


export const CredentialsInput = props => {
    const {choices} = props;
    const {values} = useFormState();
    const [bank, setBank] = useState(choices.find(x => x.id == values.bank))
    useEffect(() => {
        setBank(choices.find(x => x.id == values.bank))
    }, [values, values.bank, choices])
    return (
        bank?.fields ? 
            <Box>
                {bank.fields.map(field => 
                        <TextInput 
                        className={'RaFormInput-input-58'} 
                        name={`credentials.${field}`} 
                        placeholder={field} 
                        label={field} 
                        validate={required()} 
                        multiline 
                        fullWidth
                    />
                )}
            </Box>
            : null
    )
}