
import React from "react";
import {
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import { CardContent, Card as MuiCard } from '@material-ui/core';

const PSSideFilter = (props) => {
    return (      
            <MuiCard style={
                {
                    margin: '0', 
                    padding: '0', 
                    width: '100%', 
                    height: '100%',  
                    display: 'flex', 
                    top: 0, 
                    alignItems: 'flex-start',
                    marginTop: '2em'
                }
            }>
                <CardContent 
                    style={
                    {
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        heith: '100%',
                        transition: 'width 2s, height 2s, background-color 2s, transform 2s',

                    } }
                >
                    <ReferenceInput label="Company" source="company_id" reference="company" perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                        <SelectInput optionText="name" resettable/>
                    </ReferenceInput>
                    <ReferenceInput label="Team" source="team_id" reference="team" perPage={1000} sort={{field: 'pp_id', order: 'ASC'}}>
                        <SelectInput optionText="pp_id" resettable/>
                    </ReferenceInput>
                    <ReferenceInput label="Payment System" source="bank" reference="bank" perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                        <SelectInput optionText="name" resettable />
                    </ReferenceInput>
                </CardContent>
            </MuiCard>
    )
};

export default PSSideFilter;