import React from 'react';
import { Create,  SimpleForm, SelectInput, ReferenceInput, required} from 'react-admin';
import { CredentialsInput } from './CredentialsInput';
import { Box } from '@material-ui/core';


export const CompanyCredentialsCreate = (props) => {
    return (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label='Company' source='company_id' reference='company' perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText='name'  validate={required()} />
            </ReferenceInput>
            <ReferenceInput label='PaymentSystem' source='bank' reference='bank' perPage={1000}>
                <SelectInput optionText='name'  validate={required()} />
            </ReferenceInput>
            <Box>
                <ReferenceInput label='PaymentSystem' source='bank' reference='bank' perPage={1000}>
                    <CredentialsInput />
                </ReferenceInput>
            </Box>
        </SimpleForm>
    </Create>)
};