
import React from 'react';
import {
    Create,
    SimpleForm, 
    TextInput, 
    BooleanInput, 
    PasswordInput, 
    SelectInput, 
    ReferenceInput, 
    required,
    FormDataConsumer
} from 'react-admin';
import { Box } from '@material-ui/core';


export const OwnerUserCreate = (props) =>  (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="login" source="login" validate={required()} />
            <PasswordInput label="password" source="password" validate={required()}/>
            <ReferenceInput source="role" reference="role" perPage={1000}>
                <SelectInput validate={required()}  translateChoice={false} resettable />
            </ReferenceInput>
            <Box>
            <FormDataConsumer>
                {({ formData }) => 
                    formData.role && formData.role !== 'owner' &&
                    <ReferenceInput className="RaFormInput-input-58" source="parent_id" reference="user" perPage={1000} sort={{field: 'login', order: 'ASC'}} fullWidth>
                        <SelectInput optionText="login" validate={required()} resettable />
                    </ReferenceInput>
                }
            </FormDataConsumer>
            </Box>
            <BooleanInput label="Active" source="is_active" defaultValue />
        </SimpleForm>
    </Create>
);