
import React from 'react';
import { Create,  SimpleForm, TextInput, SelectInput, ReferenceInput, NumberInput, required, BooleanInput} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import monthes from '../../Dictionaries/monthes';
import years from '../../Dictionaries/yaers';
import formatCardNumber from '../../Utils/formatCardNumber';
import cardStatuses from '../../Dictionaries/cardStatuses';

const CardDateExpInput = (props) => {
    return (
        <Box display={'inline-flex'} style={{'width': 'auto'}}>
            <SelectInput  label="Month" source="exp_month" choices={monthes} optionText="month" validate={required()} style={{'maxWidth': '46%', 'minWidth': '98%'}} />
            &nbsp;
            <SelectInput  label="Year" source="exp_year" choices={years} optionText="year" validate={required()} style={{'maxWidth': '46%', 'minWidth': '98%'}} />
        </Box>
    )
}

export const CardCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <Box>
                <ReferenceInput label="Account" source="account_id" perPage={1000} reference="account"  sort={{field: 'description', order: 'ASC'}}>
                    <SelectInput optionText="description" fullWidth  validate={required()} />
                </ReferenceInput>
                <TextInput label="CardHolder" source="cardholder" fullWidth/>
            
                <TextInput 
                    label="Card Number"
                    source="pan" 
                    fullWidth  
                    validate={required()}
                    format={v => formatCardNumber(v)}
                    parse={v => v.replace(/[^0-9]+/g, "")}
                    onKeyPress={(event)=> {
                        if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
                            event.preventDefault(); 
                        }
                    }}
                    onPaste={(event) => {
                        let paste = (event.clipboardData || window.clipboardData).getData('text');
                        paste = formatCardNumber(paste)
                        event.target.replace = paste
                    }}
                />

                <CardDateExpInput fullWidth/>
                <TextInput 
                    label="Code" 
                    source="code" 
                    fullWidth 
                    validate={required()}
                    onKeyPress={(event)=> {
                        if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
                            event.preventDefault(); 
                        }
                        if(event.target.value.length >= 3) {
                            event.preventDefault(); 
                        }
                    }}
                />
                <SelectInput label="status" source="status" fullWidth choices={cardStatuses}  validate={required()} defaultValue={'active'}/>
                <BooleanInput label="hidden" source="hidden" fullWidth defaultValue />
            </Box>
        </SimpleForm>
    </Create>
);

