import React, {useState} from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    Button,
    TopToolbar, 
    CreateButton, 
    ExportButton, 
    TextInput, 
    ReferenceField,
    Filter
} from 'react-admin';
import SideBar from "../Common/SideBar";
import PSSideFilter from "./Filters/PSSideFilter";
import ContentFilter from '@material-ui/icons/FilterList';
import CsvExporter from "../Common/CsvExporter";
import CustomPagination from "../Common/Pagination";


export const PaymentSystemAccountList = (props) => {
    const [showFilter, setShowFilter] = useState(false);

    const AListActions = (props) => (
        <TopToolbar>
            <CreateButton/>
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
            <CsvExporter />
        </TopToolbar>
    );


    return (
    <List 
        {...props}
        bulkActionButtons={false} 
        title="PaymentSystemAccount" 
        filters={
            <Filter>
                <TextInput label="Search" source="q" alwaysOn />
                <SideBar flag={showFilter} flagSetter={setShowFilter} alwaysOn>
                    <PSSideFilter />
                </SideBar>
            </Filter>
        } 
        actions={<AListActions/>}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            {/* <ReferenceField label="User" source="user_id" reference="user">
                <TextField source="login" />
            </ReferenceField> */}
            <ReferenceField label="User PP id" source="team_id" reference="team">
                <TextField source="pp_id" />
            </ReferenceField>
            <ReferenceField label="Company" source="company_id" reference="company">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="bank" label="Payment System"/>
            <TextField source="external_id" label="ExternalConnection"/>
        </Datagrid>
    </List>)
};