import * as React from 'react';
import { memo } from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'ra-core';

import { sanitizeFieldRestProps } from 'ra-ui-materialui';

const hasNumberFormat = !!(
    typeof Intl === 'object' &&
    Intl &&
    typeof Intl.NumberFormat === 'function'
);

export const AmountField = memo(props => {
    const {
        className,
        emptyText,
        source,
        locales,
        options,
        scale,
        textAlign,
        ...rest
    } = props;
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }
    const value = scale? (get(record, source) / 10**scale): get(record, source) ;
    if (value == null) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : null;
    }

    return (
        <Typography
            variant="body2"
            component="span"
            style={{color: isNaN(value) ? 'inherit' : value > 0 ? 'green': 'red'}}
            className={className}
            {...sanitizeFieldRestProps(rest)}
        >
            {isNaN(value) ? '-' :
                hasNumberFormat ? value.toLocaleString(locales, options) : value
            }
        </Typography>
    );
});


AmountField.defaultProps = {
    addLabel: true,
    textAlign: 'center',
};


export default AmountField;
