import React, {useState} from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    TopToolbar,
    DateField, 
    ReferenceField,
    Button,
    Filter,
    TextInput,
} from 'react-admin';
import AmountField from "../../Common/AmountField";
import SideBar from "../../Common/SideBar";
import UserSideFilter from "../Filters/UserSideFilter";
import ContentFilter from '@material-ui/icons/FilterList';
import CsvExporter from "../../Common/CsvExporter";
import CustomPagination from "../../Common/Pagination";



export const UserBankTransactionList = (props) => {
    const [showFilter, setShowFilter] = useState(false);

    const AListActions = (props) => (
        <TopToolbar>
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
            <CsvExporter />
        </TopToolbar>
    );


    return (
    <List {...props} 
        bulkActionButtons={false} 
        title="Transactions"
        filters={
            <Filter>
                <TextInput label="Search" source="q" alwaysOn /> 
                <SideBar flag={showFilter} flagSetter={setShowFilter} alwaysOn>
                    <UserSideFilter />
                </SideBar>
            </Filter>
        } 
        actions={<AListActions />}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<CustomPagination />}
    >
        <Datagrid>
            <TextField source="label" sortable={false}/>
            <DateField label="created" source="created_at" showTime={true} />
            <DateField label="completed" source="completed_at" showTime={true} />
            <ReferenceField label="Account" source="account_id" reference="account">
                <TextField source="code" />
            </ReferenceField>
            <AmountField source="amount" options={{ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }} scale={2}/>
            <TextField source="currency" />
            <TextField source="status" />
            <TextField source="card" />
        </Datagrid>
    </List>)
};