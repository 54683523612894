import React, {useState} from "react";
import { 
    List,
    Datagrid,
    TextField,
    TopToolbar, 
    CreateButton,
    ReferenceField, 
    usePermissions, 
    FunctionField,
    DateField,
    Button,
    Filter,
    TextInput,
} from 'react-admin';
import AmountField from "../../Common/AmountField";
import ContentFilter from '@material-ui/icons/FilterList';
import Sidebar from "../../Common/SideBar";
import UserSideFilter from '../Filters/UserSideFilter';
import CsvExporter from "../../Common/CsvExporter";
import CustomPagination from "../../Common/Pagination";
import ChangeCardOwner from "../actions/ChangeCardOwner";
import getFormattedCardNumberWithType from "../../../Utils/getFormattedCardNumberWithType";
import { Box } from '@material-ui/core';
import pad from "../../../Utils/pad";



export const UserCardList = (props) => { 
    const {permissions} = usePermissions();
    const [showFilter, setShowFilter] = useState(false);

    const UListActions = (props) => {
        return ( 
        <TopToolbar>
            <CreateButton label='add' />
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
            <CsvExporter />
        </TopToolbar>)
    };

    const CardBulkActionButtons = () => (
        <Box><ChangeCardOwner /></Box>
    );


    return(
    <List {...props}
        bulkActionButtons={(permissions === 'owner' || permissions === 'teamlead') ?<CardBulkActionButtons/> : false} 
        title="Cards"
        filters={
            <Filter>
                <TextInput label="Search" source="q"  key={'q'} alwaysOn/> 
                <Sidebar flag={showFilter} flagSetter={setShowFilter} alwaysOn >
                    <UserSideFilter />
                </Sidebar>
            </Filter>
        } 
        actions={<UListActions />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={'show'}>
            <FunctionField label="Card Number" render={v => getFormattedCardNumberWithType(v.pan)} sortBy="pan" />
            <FunctionField label="ExpDate" render={record => `${pad(record.exp_month, 2)}/${record.exp_year.toString().slice(-2)}`} sortable={false}/>
            {permissions == 'owner' && <AmountField 
                source="amount"  
                options={{ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }} 
                scale={2} 
            />}
            {permissions == 'owner' && <TextField source="currency" />}
            <TextField source="cardholder"  sortable={false}/>
            <ReferenceField label="Account code" source="account_id" reference="account">
                <TextField source="code" />
            </ReferenceField>
            <TextField source="label"  sortable={false}/>
            {(permissions === 'owner' || permissions === 'teamlead') &&
            <ReferenceField label="Card owner" source="user_id" reference="user">
                <TextField source="login" />
            </ReferenceField>}
            <DateField source="issued_at" label="Issued"  showTime={true} />
            <TextField source="status" />
        </Datagrid>
    </List>
)};