import {useState, React} from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    TopToolbar,
    TextInput,
    ReferenceField,
    Button,
    Filter,
} from 'react-admin';
import AmountField from "../../Common/AmountField";
import CsvExporter from "../../Common/CsvExporter";
import AdminSideFilter from "../filters/AdminSideFilter";
import SideBar from "../../Common/SideBar";
import ContentFilter from '@material-ui/icons/FilterList';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import archivedTabs from "../../../Dictionaries/archived";


export const AdminAccountList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [pfilter, setPfilter] = useState({archived: false});
    const [tabs] = useState(archivedTabs);
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const handleTabsChange = (event, value) => {
        setActiveTab(value)
        const c = tabs.find(x => x.id == value)
        setPfilter(c.value === null ? {}: {archived: c.value})
    };

    const AListActions = (props) => (
        <TopToolbar>
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
            <CsvExporter />
        </TopToolbar>
    );

    return (
    <List 
        {...props} 
        bulkActionButtons={false} 
        title="Accounts"
        filter={pfilter}
        empty={false}
        filters={
            <Filter>
                <TextInput label="Search" source="q" alwaysOn /> 
                <SideBar flag={showFilter} flagSetter={setShowFilter} alwaysOn>
                        <AdminSideFilter />
                </SideBar>
            </Filter>} 
        actions={<AListActions {...props} />}
    >
        <>
        <Tabs
            fullWidth
            left
            value={activeTab}
            indicatorColor="primary"
            onChange={handleTabsChange}
        >
            {tabs.map(choice => (
                <Tab
                    key={choice.id}
                    label={choice.name}
                    value={choice.id}
                />
            ))}
        </Tabs>
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <ReferenceField label="Team" source="team_id" reference="team">
                <TextField source="pp_id" />
            </ReferenceField>
            <TextField label="Payment System" source="bank" />
            <ReferenceField label="Company" source="payment_system_account_id" reference="payment-system-account">
                <ReferenceField source="company_id" reference="company">
                    <TextField source="name" />
                </ReferenceField>
            </ReferenceField>
            <TextField source="description" />
            <TextField source="code" />
            <TextField source="cards_limit" label="Monthly Card Limit" />
            <TextField source="cards_left" label="Card Issue Left" />
            <TextField source="cards_required" label="Card Limit" />
            <TextField source="active_cards_count" label="Active cards" />
            <TextField source="blocked_cards_count" label="Blocked cards" />
            <TextField source="hidden_cards_count" label="Hidden cards" />
            <TextField source="number" />
            <AmountField  label="amount" source="amount"  options={{ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }} scale={2}/> 
            <TextField source="currency" />
        </Datagrid>
        </>
    </List>)
};