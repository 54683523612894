import React from 'react';
import { Create,  SimpleForm, TextInput, SelectInput, ReferenceInput, required} from 'react-admin';
import geos from '../../Dictionaries/geos';

export const CompanyCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name"  validate={required()}/>
            <TextInput label="Identifier" source="id_number"  validate={required()}/>
            <SelectInput source="geo" choices={geos}  validate={required()}/>
            <ReferenceInput label='Proxy' source='proxy_server_id' reference='proxy-server' perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText='name' />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);