import React from "react";
import { 
    List, 
    Datagrid, 
    TextField,
    Filter,
    TextInput,
    TopToolbar,
    CreateButton,
    BooleanField,
    DateField
} from 'react-admin';
import CustomPagination from "../Common/Pagination";


export const TeamList = (props) => {

    const AListActions = () => (
        <TopToolbar>
            <CreateButton/>
        </TopToolbar>
    )

    return (
    <List 
        {...props} 
        bulkActionButtons={false} 
        title="Teams" 
        filters={
            <Filter>
                <TextInput label="Search" source="q" alwaysOn />
            </Filter>
        } 
        actions={<AListActions/>}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <TextField source="pp_id" label="PP user id" />
            <DateField source="created_at" label="created"  showTime={true} />
            <BooleanField source="is_active" />
        </Datagrid>
    </List> )
};