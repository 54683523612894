import React from 'react';
import { Edit,  SimpleForm, ReferenceInput, SelectInput} from 'react-admin';
import { CredentialsInput } from './CredentialsInput';
import { Box } from '@material-ui/core';


export const CompanyCredentialsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label='Company' source='company_id' reference='company' perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput label='PaymentSystem' source='bank' reference='bank' perPage={1000} sort={{field: 'name', order: 'ASC'}} >
                <SelectInput optionText='name'  />
            </ReferenceInput>
            <Box>
                <ReferenceInput label='PaymentSystem' source='bank' reference='bank' perPage={1000} >
                    <CredentialsInput />
                </ReferenceInput>
            </Box>
            
        </SimpleForm>
    </Edit>
);