import React from "react";
import { Drawer, Button} from '@material-ui/core';
import LastPageIcon from '@material-ui/icons/LastPage';



const SideBar = (props) => {
    const {anchor = 'right', flag, flagSetter, children} = props;
    return (
        <React.Fragment key={anchor}>
            {/* <Button onClick={() => flagSetter(false)}>{anchor}</Button> */}
            <Drawer
            anchor={anchor}
            open={flag}
            onClose={() => flagSetter(false)}
            variant={'persistent'}
            >
                {children}
                <Button onClick={() => flagSetter(false)}><LastPageIcon /></Button>
            </Drawer>
        </React.Fragment>
    )
}
export default SideBar;