import React, {useState} from "react";
import { 
    List,
    Datagrid,
    TextField,
    TopToolbar, 
    CreateButton,
    ReferenceField,  
    FunctionField, 
    BooleanField,
    DateField,
    Button,
    Filter,
    TextInput,
} from 'react-admin';
import AmountField from "../../Common/AmountField";
import ContentFilter from '@material-ui/icons/FilterList';
import Sidebar from "../../Common/SideBar";
import AdminSideFilter from '../Filters/AdminSideFilter';
import CsvExporter from "../../Common/CsvExporter";
import CustomPagination from "../../Common/Pagination";
import getFormattedCardNumberWithType from "../../../Utils/getFormattedCardNumberWithType";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import archivedTabs from "../../../Dictionaries/archived";
import pad from "../../../Utils/pad";


export const AdminCardList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [pfilter, setPfilter] = useState({archived: false});
    const [tabs] = useState(archivedTabs);
    const [activeTab, setActiveTab] = useState(tabs[0].id)

    const AListActions = (props) => {
        return ( 
        <TopToolbar>
            <CreateButton/>
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
            <CsvExporter />
        </TopToolbar>)
    };

    const handleTabsChange = (event, value) => {
        setActiveTab(value)
        const c = tabs.find(x => x.id == value)
        setPfilter(c.value === null ? {}: {archived: c.value})
    };


    return(
    <List {...props}
        bulkActionButtons={false} 
        empty={false}
        title="Cards"
        filter={pfilter}
        filters={
            <Filter>
                <TextInput label="Search" source="q"  key={'q'} alwaysOn/> 
                <Sidebar flag={showFilter} flagSetter={setShowFilter} alwaysOn >
                        <AdminSideFilter />
                </Sidebar>
            </Filter>
        } 
        actions={<AListActions />}
        pagination={<CustomPagination />}
    >
        <>
        <Tabs
            fullWidth
            left
            value={activeTab}
            indicatorColor="primary"
            onChange={handleTabsChange}
        >
            {tabs.map(choice => (
                <Tab
                    key={choice.id}
                    label={choice.name}
                    value={choice.id}
                />
            ))}
        </Tabs>
        <Datagrid rowClick={'show'}>
            <TextField source="id" />
            <FunctionField label="Card Number" render={v => getFormattedCardNumberWithType(v.pan)} sortBy="pan" />
            <FunctionField label="ExpDate" render={record => `${pad(record.exp_month, 2)}/${record.exp_year.toString().slice(-2)}`} sortable={false}/>
            <AmountField source="amount"  options={{ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }} scale={2} />
            <TextField source="currency" />
            <TextField source="cardholder"  sortable={false}/>
            <ReferenceField label="Account code" source="account_id" reference="account">
                <TextField source="code" />
            </ReferenceField>
            <ReferenceField label="Card owner" source="user_id" reference="user">
                <TextField source="login" />
            </ReferenceField>
            <DateField source="created_at" label="Created"  showTime={true} />
            <DateField source="issued_at" label="Issued"  showTime={true} />
            <ReferenceField label="Account desc" source="account_id" reference="account">
                <TextField source="description" />
            </ReferenceField>
            <TextField source="status" />
            <ReferenceField label="Team" source="account_id" reference="account">
                <ReferenceField source="team_id" reference="team">
                    <TextField source="pp_id" />
                </ReferenceField>
            </ReferenceField>
            <BooleanField source="hidden" />
        </Datagrid>
        </>
    </List>
)};