import config from "../config";

const backUrl = config.backUrl;
const apiUrl = config.apiUrl;
const csrf = config.csrf;

const authProvider = {
    login: async ({ username, password }) =>  {
        if (csrf == 'enable')
            await fetch(`${backUrl}/sanctum/csrf-cookie`, { credentials: 'include'});
        const request = new Request(`${backUrl}/login`, {
            method: 'POST',
            body: JSON.stringify({  login: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json', Accept: 'application/json'}),
            credentials: 'include'
        });

         return   fetch(request, {credentials: 'include', Accept: 'application/json'})
            .then(response => {
                if (response.status == 302) {
                    return response.json()
                }
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(() => {
                localStorage.setItem('authorized', true);
            }).catch(e => { throw new Error(e.message)})
       
    },
    logout: () => Promise.resolve(localStorage.removeItem('authorized')),
    checkAuth: () => new Promise((resolve, reject) => {
        if (localStorage.getItem('authorized')) {
            resolve();
        }

        reject();
    }),
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('authorized');
            return Promise.reject();
        }
        localStorage.setItem('authorized', true)
        return Promise.resolve();
    },
    getPermissions: params => {
        const r = new Request(`${apiUrl}/profile`, {
            method: 'GET',
            credentials: 'include',
        })
        return fetch(r).then(response => response.json().then(json => json.data.role))
    },
};

export default authProvider;