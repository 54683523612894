import React, {useState} from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    BooleanField, 
    Filter, 
    usePermissions, 
    TopToolbar, 
    CreateButton,
    TextInput,
    Button,
    ReferenceField,
} from 'react-admin';
import SideBar from "../Common/SideBar";
import UserSideFilter from "./Filters/UserSideFilter";
import ContentFilter from '@material-ui/icons/FilterList';
import CsvExporter from "../Common/CsvExporter";
import CustomPagination from "../Common/Pagination";
import AdminSideFilter from "./Filters/AdminSideFilter";


export const UserList = (props) => {
    const {permissions} = usePermissions();
    const [showFilter, setShowFilter] = useState(false);

    const AListActions = (props) => (
        <TopToolbar>
            <CreateButton/>
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
            <CsvExporter />
        </TopToolbar>
    )

    const UListActions = (props) => (
        <TopToolbar>
            <CreateButton/>
            <Button label="Filter" onClick={()=> setShowFilter(true)}><ContentFilter/></Button>
        </TopToolbar>
    )

    return (
    <List 
        {...props} 
        bulkActionButtons={false} 
        title="Users" 
        filters={
            <Filter>
                <TextInput label="Search" source="q" alwaysOn />
                <SideBar flag={showFilter} flagSetter={setShowFilter} alwaysOn>
                    {permissions == 'admin'? <AdminSideFilter /> : <UserSideFilter />}
                </SideBar>
            </Filter>
        } 
        actions={permissions == 'admin' ? <AListActions/> : <UListActions/>}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={'edit'}>
            <TextField source="id" />
            <TextField source="login" />
            <TextField source="role" />
            {permissions == 'admin' && 
                <ReferenceField source="team_id" reference="team">
                    <TextField source="pp_id" />
                </ReferenceField>}
            <BooleanField source="is_active" label="Active"/>
        </Datagrid>
    </List> )
};