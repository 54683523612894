import React from 'react';
import { Edit,  SimpleForm, TextInput, SelectInput, ReferenceInput} from 'react-admin';
import geos from '../../Dictionaries/geos';

export const CompanyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput label="Name" source="name" />
            <TextInput label="Identifier" source="id_number" />
            <SelectInput source="geo" choices={geos}/>
            <ReferenceInput label='Proxy' source='proxy_server_id' reference='proxy-server' perPage={1000} sort={{field: 'name', order: 'ASC'}}>
                <SelectInput optionText='name' />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);