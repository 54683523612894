
import React from 'react';
import { usePermissions } from 'react-admin';
import { AdminCardEdit } from './EditForms/AdminCardEdit';
import { UserCardEdit } from './EditForms/UserCardEdit';

export const CardEdit = (props) => {
    const {permissions} = usePermissions();
    return (
        permissions == 'admin' ? <AdminCardEdit {...props} /> : <UserCardEdit {...props} />
)}

