
import React from "react";
import {
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { CardContent, Card as MuiCard } from '@material-ui/core';

const DashboardSideFilter = (props) => {
    return (      
            <MuiCard style={
                {
                    margin: '0', 
                    padding: '0', 
                    width: '100%', 
                    height: '100%',  
                    display: 'flex', 
                    top: 0, 
                    alignItems: 'flex-start',
                    marginTop: '2em'
                }
            }>
                <CardContent 
                    style={
                    {
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        heith: '100%',
                        transition: 'width 2s, height 2s, background-color 2s, transform 2s',

                    } }
                >
                    <ReferenceInput label="User" source="user_id" reference="user" perPage={1000} sort={{field: 'login', order: 'ASC'}} >
                        <SelectInput optionText="login"  resettable/>
                    </ReferenceInput>
                    <ReferenceInput label="Account" source="account_id" reference="account" perPage={1000} sort={{field: 'code', order: 'ASC'}} >
                        <SelectInput optionText="code"  resettable/>
                    </ReferenceInput>
                    <ReferenceInput label="Description" source="account_id" reference="account" perPage={1000} sort={{field: 'description', order: 'ASC'}}>
                        <SelectInput optionText="description"  resettable/>
                    </ReferenceInput>
                </CardContent>
            </MuiCard>
    )
};

export default DashboardSideFilter;