
import React from "react";
import {
    SelectInput,
    BooleanInput,
    ReferenceInput,
} from 'react-admin';
import { CardContent, Card as MuiCard } from '@material-ui/core';

const AdminSideFilter = (props) => {
    return (      
            <MuiCard {...props} style={
                {
                    margin: '0', 
                    padding: '0', 
                    width: '100%', 
                    height: '100%',  
                    display: 'flex', 
                    top: 0, 
                    alignItems: 'flex-start',
                    marginTop: '2em'
                }
            }>
                <CardContent 
                    style={
                    {
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        heith: '100%',
                        transition: 'width 2s, height 2s, background-color 2s, transform 2s',

                    } }
                >
                    <ReferenceInput source="role"  reference="role" >
                        <SelectInput resettable />
                    </ReferenceInput>
                    <ReferenceInput source="team_id" reference="team" perPage={1000} sort={{field: 'pp_id', order: 'ASC'}}>
                        <SelectInput optionText="pp_id" resettable />
                    </ReferenceInput>
                    {/* <ReferenceInput source="pp_id" reference="user" perPage={1000}   >
                        <PPIDselect />
                    </ReferenceInput> */}
                    <BooleanInput label="Active" source="is_active" />
                </CardContent>
            </MuiCard>
    )
};

export default AdminSideFilter;