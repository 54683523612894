
import React from 'react';
import { 
    Create,
    SimpleForm, 
    TextInput, 
    BooleanInput,
    required
} from 'react-admin';

export const TeamCreate = (props) => {

    return (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="PP id" source="pp_id" validate={required()}/>
            <BooleanInput label="Active" source="is_active" defaultValue />
        </SimpleForm>
    </Create>)
};