import * as React from "react";
import { Admin, Resource, fetchUtils} from 'react-admin';
import authProvider from "./Auth/authProvider";
import { UserCreate} from "./Entities/User/UserCreate";
import { UserEdit} from "./Entities/User/UserEdit";
import {  UserList } from "./Entities/User/UserList";

// import jsonServerProvider from 'ra-data-json-server';
import jsonServerProvider from './DP/dp'
import { AccountEdit } from "./Entities/Account/AccountEdit";
import { CardCreate } from "./Entities/Card/CardCreate";
import { CompanyList } from "./Entities/Company/CompanyList";
import { CompanyEdit } from "./Entities/Company/CompanyEdit";
import { CompanyCreate } from "./Entities/Company/CompanyCreate";
import { PaymentSystemAccountList } from "./Entities/PaymentSystemAccount/PaymentSystemAccountList";

import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import CreditCard from '@material-ui/icons/CreditCard';
import Group from '@material-ui/icons/Group';
import Business from '@material-ui/icons/Business';
import Receipt from '@material-ui/icons/Receipt';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import AssessmentIcon from '@material-ui/icons/Assessment';
import VpnLockIcon from '@material-ui/icons/VpnLock';

import { PaymentSystemAccountEdit } from "./Entities/PaymentSystemAccount/PaymentSystemAccountEdit";
import { PaymentSystemAccountCreate } from "./Entities/PaymentSystemAccount/PaymentSystemAccountCreate";
import { CompanyCredentialsCreate } from "./Entities/CompanyCredentials/CompanyCredentialsCreate";
import { CompanyCredentialsList } from "./Entities/CompanyCredentials/CompanyCredentialsList";
import { CompanyCredentialsEdit } from "./Entities/CompanyCredentials/CompanyCredentialsEdit";
import { CardIssue } from "./Entities/Card/CardIssue";
import { CardShow } from "./Entities/Card/CardShow";
import { CardEdit } from "./Entities/Card/CardEdit";
import { DashboardList } from "./Entities/Dashboard/DashboardList";
import { ProxyServerCreate } from "./Entities/ProxyServer/ProxyServerCreate";
import { ProxyList } from "./Entities/ProxyServer/ProxyList";
import { ProxyServerEdit } from "./Entities/ProxyServer/ProxyServerEdit";
import { TeamList } from "./Entities/Team/TeamList";
import { TeamCreate } from "./Entities/Team/TeamCreate";
import { TeamEdit } from "./Entities/Team/TeamEdit";
import { AdminCardList } from "./Entities/Card/ListForms/AdminCardList";
import { UserCardList } from "./Entities/Card/ListForms/UserCardList";
import { AdminBankTransactionList } from "./Entities/BankTransaction/ListForms/AdminBankTransactionList";
import { UserBankTransactionList } from "./Entities/BankTransaction/ListForms/UserBankTransactionList";
import { AdminAccountList } from "./Entities/Account/ListForms/AdminAccountList";
import { UserAccountList } from "./Entities/Account/ListForms/UserAccountList";




const fetchJson = (url, options = {}) => {
        options.headers = new Headers({ Accept: 'application/json' });
        options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
}
const dp = jsonServerProvider(fetchJson)



const App = () => ( 
    <Admin dataProvider = {dp} authProvider = {authProvider}>
        {permissions =>
            [
            permissions === "admin" && <Resource icon={AssessmentIcon} name = "statistics/cards" options={{label:"Stats"}} list={DashboardList} />,
            permissions === "admin" && <Resource icon={AssessmentIcon} name = "team" list={TeamList} create={TeamCreate} edit={TeamEdit}/>,
            permissions !== "buyer" && <Resource icon={Group} name = "user" perPage={false}  list={UserList} show={false} edit={UserEdit} create={UserCreate} />,
            permissions === "admin" && <Resource icon={Business} name = "company"  list={CompanyList} edit={CompanyEdit} create={CompanyCreate}/>,
            permissions === "admin" && <Resource icon={Business} name = "company-credentials" options={{label:"CCredentials" }} list={CompanyCredentialsList} create={CompanyCredentialsCreate} edit={CompanyCredentialsEdit}/>,
            permissions === "admin" && <Resource icon={VpnLockIcon} name = "proxy-server" options={{label:"ProxyList"}} list={ProxyList} edit={ProxyServerEdit} create={ProxyServerCreate}/>,
            permissions === "admin" && <Resource icon={AssignmentInd} name = "payment-system-account" options={{label:"PaySysAccount" }} list={PaymentSystemAccountList} create={PaymentSystemAccountCreate} edit={PaymentSystemAccountEdit}/>,
            <Resource name = "account" icon={AccountBalanceWallet} list={permissions === "admin" ? AdminAccountList : UserAccountList} edit={permissions === "admin" ? AccountEdit : null}   /> ,
            <Resource name = "card" icon={CreditCard} list={permissions === "admin" ? AdminCardList : UserCardList} create={permissions === "admin" ? CardCreate : CardIssue} show={CardShow} edit={CardEdit} />,
            <Resource name = "transaction" icon={Receipt} list={permissions === "admin" ? AdminBankTransactionList : UserBankTransactionList} />,
            permissions === "admin" && <Resource name="bank"/>,
            permissions !== "buyer" && <Resource name="role"/>
        ]}
    </Admin >
);

export default App;

    