const geos = {
    AR: 'Argentina',
    AT: 'Austria',
    BG: 'Bulgaria',
    BO: 'Bolivia',
    BR: 'Brazil',
    BY: 'Belarus',
    CH: 'Switzerland',
    CL: 'Chile',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CZ: 'Czech',
    DE: 'Germany',
    EC: 'Ecuador',
    ES: 'Spain',
    FR: 'France',
    GH: 'Ghana',
    GR: 'Greece',
    HU: 'Hungary',
    IT: 'Italy',
    KE: 'Kenya',
    KZ: 'Kazakhstan',
    LK: 'Sri Lanka',
    LT: 'Lithuania',
    MD: 'Moldova',
    MX: 'Mexico',
    MY: 'Malaysia',
    NG: 'Nigeria',
    NO: 'Norway',
    PA: 'Panama',
    PE: 'Peru',
    PL: 'Poland',
    PT: 'Portugal',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Singapore',
    SI: 'Slovenia',
    SK: 'Slovakia',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TW: 'Taiwan',
    UA: 'Ukraine',
    UK: 'United Kingdom',
  };

const geosArray =  Object.keys(geos).map((geo) => ({ 'id': geo, 'name': geos[geo] }))

export default geosArray;