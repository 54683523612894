import detectCardType from "./checkCardNumber";
import formatCardNumber from "./formatCardNumber";

export default function getFormattedCardNumberWithType(number){
    let n = formatCardNumber(number);
    let type = detectCardType(n);
    let s = `●${n.substr(n.lastIndexOf(' '))}`
    // return type ? `${type} ${s}` : `${s}`
    return s;
}
