
import React from "react";
import {
    SelectInput,
    ReferenceInput,
    DateInput,
} from 'react-admin';
import cardStatuses from "../../../Dictionaries/cardStatuses";
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CardContent, Card as MuiCard } from '@material-ui/core';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            // width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex'
            // marginTop: '1.5em'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const PPIDselect = props => {
    const {choices} = props
    const uniqueChoices = [...new Set(choices.map(x =>  x.pp_id))].map(x => ({pp_id:`${x}`}))
    return <SelectInput label="PPid" source="pp_id"  optionText="pp_id" optionValue="pp_id"  choices={uniqueChoices} resettable/>
}

const AdminSideFilter = (props) => {
    return (      
            <MuiCard {...props} style={
                {
                    margin: '0', 
                    padding: '0', 
                    width: '100%', 
                    height: '100%',  
                    display: 'flex', 
                    top: 0, 
                    alignItems: 'flex-start',
                    marginTop: '2em'
                }
            }>
                <CardContent 
                    style={
                    {
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        heith: '100%',
                        transition: 'width 2s, height 2s, background-color 2s, transform 2s',

                    } }
                >
                    <ReferenceInput label="Team" source="team_id" reference="team" perPage={1000} sort={{field: 'pp_id', order: 'ASC'}}>
                        <SelectInput optionText="pp_id" resettable/>
                    </ReferenceInput>
                    <ReferenceInput label="Account" source="account_id" reference="account" perPage={1000} sort={{field: 'code', order: 'ASC'}}>
                        <SelectInput optionText="code"  resettable/>
                    </ReferenceInput>
                    <SelectInput label="Status" source="status" choices={cardStatuses} resettable/>
                    <SelectInput label="hidden" choices={[
                        {id: 'true',name: 'hidden'},
                        {id: 'false',name: 'visible'},
                    ]} source="hidden" resettable  optionText="name"/>
                    <Box>
                        <Box>
                            Created
                        </Box>
                        <Box display={'inline-flex'} >
                            <DateInput source="created_at_from" label="from" />
                            <Box>&nbsp;</Box>
                            <DateInput source="created_at_to" label="to"   />
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            Issued
                        </Box>
                        <Box display={'inline-flex'} >
                            <DateInput source="issued_at_from" label="from" />
                            <Box>&nbsp;</Box>
                            <DateInput source="issued_at_to" label="to"   />
                        </Box>
                    </Box>
                </CardContent>
            </MuiCard>
    )
};

export default AdminSideFilter;